import React from 'react';
import ProductGroup from '../components/Product/ProductGroup';


// Create the HomePage
const HomePage: React.FC = () => {

    return (
        <div className='xl:container xl:mx-auto xl:w-[1300px] h-auto flex flex-col space-y-10'>
            <ProductGroup title='Новинки' allTitle='Все новинки '/>
            <ProductGroup title='Бестселлеры' allTitle='Все бестселлеры '/>
            <ProductGroup title='Лучшие цены' allTitle='Все книги '/>
        </div>
    );

}

export default HomePage;