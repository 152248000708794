import React, {FC} from 'react';
import {PiWarningCircleBold} from "react-icons/pi";

import '../../styles/components/warningContainer.style.scss';

interface Props {
    warningMessage: string;
}

const WarningContainer: FC<Props> = ({warningMessage}) => {
    return (
        <div className="warning-container">
            <PiWarningCircleBold/>
            <p className="warning-message">{warningMessage}</p>
        </div>
    );
};

export default WarningContainer;