import React, {useState, useEffect} from 'react';
import Header from './Header';
import Logo from './Logo';
import Search from './Search';
import User from './User';
import Basket from './Basket';
import Nav from './Nav';
import BurgerMenu from './BurgerMenu';
import ImageSlider from '../UI/ImageSlider';
import {useLocation} from 'react-router-dom';


// Define the HeaderComponent component
const HeaderComponent: React.FC = () => {
    const location = useLocation();

    const [burger, setBurger] = useState<boolean>(false);
    const slides = [
        {
            url: 'https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2620&q=80',
        },
        {
            url: 'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80',
        },
        {
            url: 'https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80',
        },

        {
            url: 'https://ndc.book24.ru/resize/2200x640/iblock/4df/4df15ca1c1d532d08a4fd52361680e2f/03b254bb55c1f8d9b31c6e272c52f902.jpg',
        },
        {
            url: 'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80',
        },
    ];


    const handleResize = () => {
        if (window.innerWidth <= 1023) {
            setBurger(true)
        } else if (window.innerWidth > 1023) {
            setBurger(false)
        }
    };

    useEffect(() => {
        // Add event listener to listen for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Header>
            <div className='xl:container xl:mx-auto xl:w-[1300px] h-32 flex flex-row space-x-2'>
                <Logo/>
                <Search/>
                <Basket/>
                {
                    burger
                        ?
                        <BurgerMenu/>
                        :
                        <User/>
                }

            </div>
            <div className='w-full h-2 bg-mainColor'>
            </div>
            <Nav/>
            {
                location.pathname === "/"
                    ?
                    <div>
                        <ImageSlider slides={slides}/>
                    </div>
                    :
                    <></>
            }
        </Header>
    );
};

export default HeaderComponent;