import React, {FC} from 'react';
import '../../../styles/components/input.style.scss'

interface Props {
    placeholder: string;
    value: string;
    setValue: (value: string) => void;
    error?: string;

}


const PasswordInput: FC<Props> = ({placeholder, value, setValue, error}) => {
    return (
        <div className='password-input'>
            <input type="password" name="" id="" placeholder={placeholder} value={value}
                   onChange={(e) => setValue(e.target.value)}/>
            {
                error
                    ?
                    <p className='error-label'>{error}</p>
                    :
                    <></>
            }
        </div>
    );
};

export default PasswordInput;