import React, {FC, ReactElement, useState} from 'react';
import {FaPlus, FaMinus} from "react-icons/fa6";
import '../../../styles/components/dropdown.style.scss';

interface DropdownProps {
    option: ReactElement;
    label: string;
}

const DropDown: FC<DropdownProps> = ({option, label}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleOptionClick = (option: string) => {
        setIsOpen(false);
    };

    return (
        <div className="dropdown-container">
            <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                <p>{label}</p>
                {/* <span className={`dropdown-icon ${isOpen ? 'open' : ''}`}></span> */}
                {
                    isOpen
                        ?
                        <FaMinus/>
                        :
                        <FaPlus/>
                }
            </div>
            {isOpen && (
                <div className="dropdown-list">
                    {option}
                </div>
            )}
        </div>
    );
};

export default DropDown;