import React from 'react';

interface Props {
    handleClose: () => void;
}


// Define the NavBody component
const NavBody: React.FC<Props> = () => {

    return (
        <div className='absolute container xl:w-[1300px] h-[500px] bg-navColor z-50 cursor-default top-12 left-[-8%]'>

        </div>
    );
};

export default NavBody;