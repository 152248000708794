import FilterList from '../components/Filter/FilterList';
import ProductCard from '../components/Product/ProductCardData';
import {useFilterStore} from '../state';
import '../styles/pages/filter.style.scss'
import React, {useEffect} from 'react';


// Create the FilterPage
const FilterPage: React.FC = () => {
    const [products, isLoading] = useFilterStore(state => [
        state.products,
        state.isLoading
    ])
    useEffect(() => {
    }, [])

    useEffect(() => {
    }, [isLoading])

    return (
        <div className='filter'>

            <div className='filter__items'>
                <FilterList/>
            </div>
            <div className='filter__products'>

                {
                    products.content
                        ?
                        products.content.length > 0
                            ?
                            products.content.map((item) => {
                                return <ProductCard
                                    id={item.id}
                                    image={item.imageUrl}
                                    title={item.title}
                                    price={item.price.toString()}
                                    author={item.author}
                                    discount={false}
                                    procent=''
                                />
                            })
                            :
                            <></>
                        :
                        <></>
                }
            </div>
        </div>
    );

}

export default FilterPage;