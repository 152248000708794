import React from "react"
import {RxHamburgerMenu} from "react-icons/rx";


const BurgerMenu: React.FC = () => {


    return (
        <div className='h-full w-1/12 flex justify-center items-center'>
            <div
                className='lg:w-36 h-14 w-14 border-2 rounded-md bg-borderColor flex flex-row justify-center items-center space-x-2 text-white cursor-pointer'>
                <RxHamburgerMenu className='h-[40px] w-[40px]'/>
            </div>
        </div>
    );
}

export default BurgerMenu;