import React, {useEffect, useState} from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {useUserStore} from '../../state';

const GoogleAuth = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [
        loginGoogle
    ] = useUserStore((state) => [
        state.loginGoogle
    ]);

    useEffect(() => {
        console.log("params => ", search)
        setIsSuccess(true)
        loginGoogle(search, navigate)
    }, []);

    if (isSuccess) {
        return <Navigate to="/" replace={true}/>
    }
    return (
        <div>

        </div>
    );
};

export default GoogleAuth;