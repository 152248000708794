import React, {ReactElement} from 'react';

// Define props for the layout component
interface HeaderProps {
    children: ReactElement[]
}

// Create the layout component
const Header: React.FC<HeaderProps> = (props) => {

    const {children} = props;

    return (
        <header className='h-auto'>
            {children.map((element) => (
                element
            ))}
        </header>
    );

}

export default Header;