import React, {FC} from 'react';
import {MdOutlineError} from "react-icons/md";

import '../../styles/components/errorContainer.style.scss';

interface Props {
    errorMessage: string;
}

const ErrorContainer: FC<Props> = ({errorMessage}) => {
    return (
        <div className="error-container">
            <MdOutlineError/>
            <p className="error-message">{errorMessage}</p>
        </div>
    );
};

export default ErrorContainer;