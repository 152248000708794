import '../../styles/components/user.style.scss'
import React, {useState} from 'react';
import {FaRegUser, FaAngleDown} from "react-icons/fa";
import {Link} from 'react-router-dom';


// Create the User component
const User: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className='h-full w-2/12 flex justify-center items-center'>
            <div
                className='user w-36 h-14 border-2 rounded-md border-borderColor flex flex-row justify-center items-center space-x-1 cursor-pointer text-borderColor'
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                style={
                    open ? {
                            color: "#e96b2d",
                            borderColor: "#e96b2d"
                        }
                        :
                        {}
                }
            >
                <FaRegUser className='h-[25px] w-[25px]'/>
                <div className='flex flex-col items-center'>
                    <p className='text-lg font-bold leading-none'>Giriş Yap</p>
                    <p className='text-sm leading-none'>veya üye ol</p>
                </div>
                <FaAngleDown className='h-[25px] w-[25px]'/>
                <div
                    className='user__content'
                    style={open ? {display: 'block'} : {}}
                >
                    <div className='triangle'></div>
                    <div className='user__body'>
                        <div className='body__header'></div>
                        <ul>
                            <li><Link to="login/sing-in">Giriş Yap</Link></li>
                            <li><Link to="login/sing-up">Üye Ol</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="">Siparişlerim</Link></li>
                            <li><Link to="">Soru ve Taleplerim</Link></li>
                            <li><Link to="">Sana Özel Fırsatlar</Link></li>
                            <li><Link to="">Hepsipay</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default User;