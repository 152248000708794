import React from 'react';
import {SlBasket} from "react-icons/sl";


// Create the Basket component
const Basket: React.FC = () => {

    return (
        <div className='h-full lg:w-2/12 w-1/12 flex justify-center items-center'>
            <div
                className='lg:w-36 h-14 w-14 border-2 rounded-md bg-borderColor flex flex-row justify-center items-center space-x-2 text-white cursor-pointer'>
                <div className='relative'>
                    <SlBasket className='h-[30px] w-[30px]'/>
                    <div
                        className='absolute bottom-3 left-4 bg-white rounded-full h-[20px] w-[20px] flex justify-center items-center'>
                        <p className='text-base font-bold text-borderColor'>0</p>
                    </div>
                </div>
                <p className='lg:inline hidden text-lg font-bold'>Sepetim</p>
            </div>
        </div>
    );

}

export default Basket;