import '../../styles/pages/login.style.scss';
import {useEffect, useState} from "react";
import {IoReload} from "react-icons/io5";
import Timer from "../../components/UI/Timer";
import {TextInput} from "../../components/UI/Inputs";
import {MainButton} from "../../components/UI/Buttons";
import {useLocation, useNavigate} from 'react-router-dom';
import {useUserStore} from "../../state";
import Spinner from '../../components/UI/Spinner';


const Verify = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [timerFinished, setTimerFinished] = useState<boolean>(false);
    const [isResend, setIsResend] = useState<boolean>(false);
    const [verifyCode, setVerifyCode] = useState<string>('');
    const [
        isLoading,
        reg,
        verificationEmail
    ] = useUserStore((state) => [
        state.isLoading,
        state.reg,
        state.verificationEmail
    ])

    useEffect(() => {
        if (location.state === null) navigate(-1);
    }, [])

    const resendVerifyCode = () => {
        if (location.state.email && location.state.password) {
            verificationEmail(location.state.email, location.state.password, navigate);
            setTimerFinished(false);
            setIsResend(true);
        }
    }

    const regEvent = () => {
        if (location.state.email && location.state.password && verifyCode) {
            reg(location.state.email, location.state.password, verifyCode, navigate)
        }
    }

    return (
        <div className='login-verify'>
            <div className='login-verify-content'>
                <div className='input-item'>
                    <TextInput
                        placeholder='Verify code'
                        value={verifyCode}
                        setValue={setVerifyCode}
                    />

                </div>
                <div className='login-verify-content-resend'>
                    {
                        timerFinished
                            ?
                            isResend
                                ?
                                isLoading
                                    ?
                                    <Spinner/>
                                    :
                                    <IoReload className='resend-button' onClick={resendVerifyCode}/>
                                :
                                <IoReload className='resend-button' onClick={resendVerifyCode}/>
                            :
                            <Timer
                                minute={5}
                                setIsFinished={setTimerFinished}
                            />
                    }
                </div>
            </div>
            <MainButton
                label="Devam et"
                submit={regEvent}
                isLoading={isResend ? false : isLoading}
            />
        </div>
    )
}

export default Verify;