import React, {useEffect} from 'react';
import {IoStar, IoShareSocialSharp} from "react-icons/io5";
import {MdOutlineRateReview} from "react-icons/md";
import ImageView from '../components/UI/ImageView';


const images = [
    "https://ndc.book24.ru/resize/820x1180/iblock/403/403cacc802636c8c178fcbdd7159db10/4ddb580257e635c53def5d8bb44fd40e.jpg",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/cover4__w410.webp",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/dopfoto00__w410.webp",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/dopfoto01__w410.webp",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/dopfoto02__w410.webp",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/dopfoto03__w410.webp",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/dopfoto04__w410.webp",
    "https://cdn.book24.ru/v2/ITD000000001318609/COVER/dopfoto05__w410.webp"
]


// Create the DetailPage
const DetailPage: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='xl:container xl:mx-auto xl:w-[1300px] h-screen flex flex-col space-y-2 py-5'>
            <div className='flex flex-row justify-between'>
                <div className=''>
                    <ul className='flex flex-row space-x-4 items-center'>
                        <li className='flex flex-row justify-between items-center space-x-1'>
                            <IoStar className='text-yellow-500 w-[20px] h-[20px]'/>
                            <p className='text-base font-bold'>
                                4,5
                            </p>
                            <p className='text-base text-navTextColor'>
                                (497)
                            </p>
                        </li>
                        <li className='flex flex-row items-center space-x-1'>
                            <MdOutlineRateReview className='text-navTextColor w-[20px] h-[20px]'/>
                            <p className='text-base'>
                                <span className='font-bold'>14</span> отзывов
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='flex flex-row space-x-4 items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <IoShareSocialSharp className='text-navTextColor w-[20px] h-[20px]'/>
                        <p className='text-base'>
                            Поделиться
                        </p>
                    </div>
                    <p className='text-base'>
                        Купили 618 раз
                    </p>
                </div>
            </div>
            <div className='flex flex-row h-[500px]'>
                <div className='w-[40%] h-full'>
                    <ImageView images={images}/>
                </div>
                <div className=' w-[60%] h-full flex flex-col px-2'>
                    <div className='h-[50%] flex flex-col'>
                        <p className='text-3xl'>
                            Гуйюань Тянься: Восстание клана Чан
                        </p>
                    </div>
                    <div className='flex flex-col h-[50%]'>
                        <h1 className='text-xl font-bold'>Характеристики</h1>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default DetailPage;