import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {MainButton} from "../../components/UI/Buttons";
import {PasswordInput, TextInput} from "../../components/UI/Inputs";
import '../../styles/pages/login.style.scss';
import {useUserStore} from "../../state";

const Registration = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    let location = useLocation();

    useEffect(() => {
    }, [location]);

    const [
        isLoading,
        errorEmail,
        errorPassword,
        verificationEmail
    ] = useUserStore((state) => [
        state.isLoading,
        state.errorEmail,
        state.errorPassword,
        state.verificationEmail,
    ])

    const regEvent = () => {
        if (email && password) {
            verificationEmail(email, password, navigate);
        }
    }

    return (
        <div className="login-step">
            <TextInput
                placeholder='E-posta adresi veya telefon numarası'
                error={errorEmail}
                value={email}
                setValue={setEmail}
            />
            <PasswordInput
                placeholder="Password"
                error={errorPassword}
                value={password}
                setValue={setPassword}
            />
            <MainButton
                label="Devam et"
                submit={regEvent}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Registration;