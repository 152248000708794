import {create} from "zustand";
import {Auth, ResponseError, ResponseSuccess, UserState} from "../models";
import {loginSrv} from "../service";
import {isValidEmail} from "../utils/emailValidation";
import {isValidPhoneNumber} from "../utils/phoneNumberValidation";
import {AxiosError, AxiosResponse} from "axios";
import {getVerificationCode, loginGoogleSrv, registration} from "../service/user.service";
import {NavigateFunction} from "react-router-dom";


const useUserStore = create<UserState>()((set, get) => ({
    email: '',
    accessToken: localStorage.getItem("accessToken") || '',
    refreshToken: localStorage.getItem("refreshToken") || '',
    generalError: '',
    warningMessage: '',
    successMessage: '',
    errorEmail: '',
    errorPassword: '',
    isSuccess: false,
    isLoading: false,
    login: (email: string, password: string, navigate: NavigateFunction) => {
        const {resetErrors} = get();
        set({
            isLoading: true
        })
        if (isValidEmail(email)) {

        } else if (isValidPhoneNumber(email)) {
        } else {
            set({
                errorEmail: 'Geçerli bir e-posta adresi veya numara girmelisiniz.'
            })
        }

        const res = loginSrv(email, password);
        res.then((response: AxiosResponse<ResponseSuccess<Auth>>) => {
            set({
                accessToken: response.data.success.data.accessToken,
                refreshToken: response.data.success.data.refreshToken
            });
            localStorage.setItem('accessToken', response.data.success.data.accessToken);
            localStorage.setItem('refreshToken', response.data.success.data.refreshToken);
            set({
                isLoading: false
            })
            resetErrors();
            navigate('/')
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
                if (error.response.status === 401) {
                    const errorData = error.response.data;
                    set({
                        generalError: errorData.user.message
                    })
                }
            }
            console.log(error.status);

        })
        set({
            isLoading: false,
            isSuccess: true
        })

    },
    loginGoogle: (query: string, navigate: NavigateFunction) => {
        const {resetErrors} = get();
        set({
            isLoading: true
        })

        const res = loginGoogleSrv(query);
        res.then((r: AxiosResponse<ResponseSuccess<Auth>>) => {
            set({
                accessToken: r.data.success.data.accessToken,
                refreshToken: r.data.success.data.refreshToken
            });
            localStorage.setItem('accessToken', r.data.success.data.accessToken);
            localStorage.setItem('refreshToken', r.data.success.data.refreshToken);
            set({
                isLoading: false
            })
            resetErrors();
            navigate('/')
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
                if (error.response.status === 401) {
                    const errorData = error.response.data;
                    set({
                        generalError: errorData.user.message
                    })
                }
            }
            console.log(error.status);

        })
        set({
            isLoading: false,
            isSuccess: true
        })

    },
    resetErrors: () => {
        set({
            successMessage: '',
            warningMessage: '',
            generalError: '',
            errorPassword: '',
            errorEmail: ''
        })
    },
    verificationEmail: (email: string, password: string, navigate: NavigateFunction) => {
        const {resetErrors} = get();
        set({
            isLoading: true,
            isSuccess: false
        })
        if (isValidEmail(email)) {

        } else if (isValidPhoneNumber(email)) {
        } else {
            set({
                errorEmail: 'Geçerli bir e-posta adresi veya numara girmelisiniz.'
            })
        }

        const res = getVerificationCode(email);
        res.then(() => {
            set({
                isLoading: false,
                isSuccess: true
            })
            resetErrors();
            navigate('/login/verify', {state: {email, password}});
            // redirect("/login/verify");
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
                if (error.response.status === 409) {
                    const errorData = error.response.data;
                    set({
                        warningMessage: errorData.user.message
                    })
                } else {
                    const errorData = error.response.data;
                    set({
                        generalError: errorData.user.message
                    })
                }
            }
            set({
                isLoading: false,
                isSuccess: false
            })

        })
    },
    reg: (email: string, password: string, code: string, navigate: NavigateFunction) => {
        const {resetErrors} = get();
        set({
            isLoading: true,
            isSuccess: false
        })
        if (isValidEmail(email)) {

        } else if (isValidPhoneNumber(email)) {
        } else {
            set({
                errorEmail: 'Geçerli bir e-posta adresi veya numara girmelisiniz.'
            })
        }

        const res = registration(email, password, code);
        res.then((r: AxiosResponse<ResponseSuccess<Auth>>) => {
            set({
                accessToken: r.data.success.data.accessToken,
                refreshToken: r.data.success.data.refreshToken
            });
            localStorage.setItem('accessToken', r.data.success.data.accessToken);
            localStorage.setItem('refreshToken', r.data.success.data.refreshToken);
            set({
                isLoading: false
            })
            resetErrors();
            navigate('/')
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
                const errorData = error.response.data;
                set({
                    generalError: errorData.user.message
                })
            }
            console.log(error.status);

        })
        set({
            isLoading: false,
            isSuccess: true
        })
    }


}))

export default useUserStore;