import {NavLink, useLocation} from 'react-router-dom';
import '../../styles/components/auth.style.scss';
import {FC, useEffect, useState} from "react";


interface Props {

};


const AuthNav: FC<Props> = () => {
    let location = useLocation();
    const [step, setStep] = useState<string>('');

    useEffect(() => {
        console.log(location.pathname)
        setStep(location.pathname)
    }, [location]);

    return (
        <div className='checkbox-items'>
            <div
                className='checkbox-button'
            >
                <NavLink to='sing-in'><p>Giriş yap</p></NavLink>
            </div>
            <div
                className='checkbox-button'
            >
                <NavLink
                    to='sing-up'
                    className={step === "/login/sing-up" || step === "/login/verify" ? 'active' : ''}
                >
                    <p>Üye ol</p>
                </NavLink>
            </div>
        </div>
    );
};

export default AuthNav;