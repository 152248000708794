import {create} from "zustand";
import {FilterItems, FilterStore, ResponseSuccess, ResponseError, FilterResponse, Result} from "../models";
import {getFilterItems} from "../service/filter.service";
import {AxiosError, AxiosResponse} from "axios";


const useFilterStore = create<FilterStore>()((set, get) => ({
    products: {} as Result,
    searchableParameter: {} as FilterItems,
    isLoading: false,
    getFilterItems: (searchText: string) => {
        set({
            isLoading: true
        })
        const res = getFilterItems(searchText);
        res.then((r: AxiosResponse<ResponseSuccess<FilterResponse>>) => {
            console.log(r.data.success.data.result.content)
            set({
                products: r.data.success.data.result,
                searchableParameter: r.data.success.data.searchableParameter,
                isLoading: false
            });
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
            }
            console.log(error.status);

        })
    },


}))

export default useFilterStore;