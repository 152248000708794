import React, {FC, useState} from 'react';
import '../../styles/components/filter.style.scss';
import {FilterObject} from '../../models';


interface Props {
    options: FilterObject[];
}

const CheckboxList: FC<Props> = ({options}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});

    const handleCheckboxChange = (option: FilterObject) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [option.id]: !prevCheckedItems[option.id],
        }));
        console.log(checkedItems)
    };

    const filteredOptions = options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="checkbox-list-container">
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <ul className="checkbox-list">
                {filteredOptions.map((option) => (
                    <li key={option.id}>
                        <label>
                            <input
                                type="checkbox"
                                checked={checkedItems[option.id]}
                                onChange={() => handleCheckboxChange(option)}
                            />
                            <span className="checkbox-custom"></span>
                            <p>{option.name}</p>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CheckboxList;
