import React, {FC} from 'react';
import {FaCheckCircle} from "react-icons/fa";

import '../../styles/components/successContainer.style.scss';

interface Props {
    errorMessage: string;
}

const SuccessContainer: FC<Props> = ({errorMessage}) => {
    return (
        <div className="success-container">
            <FaCheckCircle/>
            <p className="success-message">{errorMessage}</p>
        </div>
    );
};

export default SuccessContainer;