import React, {useState} from 'react';
import {BsChevronRight} from "react-icons/bs";
import ProductCard from './ProductCardData';


interface ProductGroupData {
    title: string;
    allTitle: string;
}


// Create the ProductGroup
const ProductGroup: React.FC<ProductGroupData> = (props) => {
    const {title, allTitle} = props;
    const [hoverAll, setHoverAll] = useState<boolean>(false)

    return (
        <div className='flex flex-col p-1'>
            <div className='flex flex-row justify-between items-center'>
                <h1 className='font-bold leading-4 text-lg'>{title}</h1>
                <div className='flex flex-row justify-between items-center hover:text-hoverColor cursor-pointer'
                     onMouseEnter={() => setHoverAll(true)} onMouseLeave={() => setHoverAll(false)}>
                    <p className='text-sm'>{allTitle}</p>
                    <BsChevronRight/>
                </div>
            </div>
            <div className='flex flex-row space-x-4 px-3 py-2'>
                <ProductCard
                    id={1}
                    image='https://ndc.book24.ru/resize/440x568/iblock/25b/25b33421a313a5ef81bf88c327c263ac/38aa2a3250c1b94bf09bc9ede0fa26ab.jpeg'
                    title='Благословение небожителей. Том 6'
                    price='1 250 ₽'
                    author='Тунсю Мосян'
                    discount={false}
                    procent=''
                />
                <ProductCard
                    id={2}
                    image='https://ndc.book24.ru/resize/440x568/iblock/00a/00a975c44a42c821089d86b7e8f21c8e/31885aebe7f64fac8064ecaae87773be.jpeg'
                    title='ДРУЗЬЯ, ЛЮБИМЫЕ И ОДНА БОЛЬШАЯ УЖАСНАЯ ВЕЩЬ. Автобиография Мэттью Перри'
                    price='954 ₽'
                    author='Мэттью Перри'
                    discount={false}
                    procent=''
                />
                <ProductCard
                    id={3}
                    image='https://cdn.book24.ru/v2/ASE000000000876808/COVER/cover13d__w220.webp'
                    title='Право налево'
                    price='474 ₽'
                    author='Наталья Шатихина'
                    discount={true}
                    procent='100%'
                />
                <ProductCard
                    id={4}
                    image='https://cdn.book24.ru/v2/ASE000000000876213/COVER/cover13d__w220.webp'
                    title='Мой театр. Книга вторая'
                    price='1 630 ₽'
                    author='Николай Цискаридзе'
                    discount={true}
                    procent='25%'
                />
                <ProductCard
                    id={5}
                    image='https://ndc.book24.ru/resize/440x568/iblock/5fd/5fd397cf2aa0bba2a89cfaacff0cbdc9/a3cc98b67690186e2f9efde5f3859b4c.jpeg'
                    title='Ты - причина. Почему мы всегда получаем то, чего заслуживаем, и как навести порядок в семье и в жизни'
                    price='600 ₽'
                    author='Юлия Ивлиева'
                    discount={false}
                    procent=''
                />
            </div>
        </div>
    );

}

export default ProductGroup;