import React, {useState, useEffect, FC} from 'react';
import '../../styles/components/timer.style.scss';


interface Props {
    minute: number;
    setIsFinished: (value: boolean) => void;
}

const Timer: FC<Props> = ({minute, setIsFinished}) => {
    const [remainingTime, setRemainingTime] = useState<number>(minute * 60); // 2 minutes in seconds

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
        }, 1000);

        // Clear the interval when the countdown reaches zero
        if (remainingTime === 0) {
            setIsFinished(true);
            clearInterval(intervalId);
        }

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, [remainingTime]); // Include remainingTime in the dependency array to update the effect when it changes

    // Convert remainingTime to minutes and seconds
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    // Display the time in MM:SS format
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return (
        <div className='timer'>
            <p>{formattedTime}</p>
        </div>
    );
};

export default Timer;