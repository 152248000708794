import {FC} from 'react';
import '../../../styles/components/mainButton.style.scss';
import Spinner from '../Spinner';

interface Props {
    label: string;
    isLoading: boolean;
    submit: (e: any) => void;
}


const MainButton: FC<Props> = ({label, isLoading, submit}) => {


    return (
        <button onClick={submit}>
            <p>
                {
                    isLoading
                        ?
                        <Spinner/>
                        :
                        label
                }
            </p>
        </button>
    );
}

export default MainButton;