import './App.scss';

import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";

import {HomePage, ErrorPage, DetailPage, FilterPage, Verify, Login, Registration, GoogleAuth} from './pages';
import MainLayout from './layouts';
import LoginLayout from './layouts/LoginLayout';


function App() {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainLayout/>}>
                <Route index element={<HomePage/>}/>
                <Route path="details/:productId" element={<DetailPage/>}/>
                <Route path="search" element={<FilterPage/>}/>
            </Route>
            <Route path="/login" element={<LoginLayout/>}>
                <Route path='sing-in' element={<Login/>}/>
                <Route path='sing-up' element={<Registration/>}/>
                <Route path='verify' element={<Verify/>}/>
            </Route>
            <Route path='/google-auth' element={<GoogleAuth/>}/>
        </Routes>
    </BrowserRouter>
}

export default App;
