import axios from "axios";

const client = axios.create({
    baseURL: 'https://berlin-dev.knijka.com/',
    headers: {
        "Accept-Language": "tr-TR"
    },
    timeout: 2000
});


export default client;