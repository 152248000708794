import '../styles/layout/login.layout.style.scss'

import React, {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import AuthNav from '../components/Auth/AuthNav';
import {useUserStore} from '../state';
import SuccessContainer from '../components/UI/SuccessContainer';
import ErrorContainer from '../components/UI/ErrorContainer';
import WarningContainer from '../components/UI/WarningContainer';
import {FcGoogle} from "react-icons/fc";
import {FaFacebook, FaApple} from "react-icons/fa";

const DEV_URL = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=email%20profile%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=https%3A%2F%2Ftokyo-dev.knijka.com%2Fgoogle-auth&client_id=356019487849-ndtlkefv5q19jis4uv3m7eeac02nmseb.apps.googleusercontent.com&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow";
const LOCAL_URL = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=email%20profile%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fgoogle-auth&client_id=356019487849-ndtlkefv5q19jis4uv3m7eeac02nmseb.apps.googleusercontent.com&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow";

// Create the layout component
const LoginLayout: React.FC = () => {
    let location = useLocation();
    const [
        successMessage,
        warningMessage,
        generalError,
        resetErrors,
    ] = useUserStore((state) => [
        state.successMessage,
        state.warningMessage,
        state.generalError,
        state.resetErrors,
        state.isSuccess,
        state.verificationEmail,
        state.reg
    ])

    useEffect(() => {

    }, []);


    const googleAuth = (e: any) => {
        window.location.href = DEV_URL;
    }

    return (
        <section className='login-layout'>

            {/* container for header */}
            <section className='login-layout_header'>
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt=""/>
            </section>

            {/* container for main */}
            <section className='login-layout_main'>

                <div className='login-container'>
                    <div className='login-general-message'>
                        {
                            warningMessage
                                ?
                                <WarningContainer warningMessage={warningMessage}/>
                                :
                                generalError
                                    ?
                                    <ErrorContainer errorMessage={generalError}/>
                                    :
                                    successMessage
                                        ?
                                        <SuccessContainer errorMessage={successMessage}/>
                                        :
                                        <></>
                        }
                    </div>
                    <div className='login-nav'>
                        <AuthNav/>
                    </div>
                    <div className='login-form'>
                        <Outlet/>
                    </div>
                </div>

            </section>

            <section className='login-layout_social_auth'>
                <div className='layout_social_auth_container'>
                    <p className='layout_social_auth_title'>Sosyal hesabın ile giriş yap</p>
                    <div className='layout_social_auth_btn'>
                        <button className='layout_social_auth_btn_apple'><FaApple/></button>
                        <button className='layout_social_auth_btn_google' onClick={googleAuth}><FcGoogle/></button>
                        <button className='layout_social_auth_btn_facebook'><FaFacebook/></button>
                    </div>
                </div>
            </section>

            {/* container for footer */}
            <section className='login-layout_footer'>

            </section>
        </section>
    );

};

export default LoginLayout;