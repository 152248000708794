import React, { useEffect, useCallback} from 'react';

const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
    const handleClick = useCallback(
        (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        },
        [ref, callback]
    );

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => handleClick(event);
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleClick]);
};

export default useOutsideClick;