import {client} from "../api"
import {FilterResponse, ResponseError, ResponseSuccess} from "../models"


export async function getFilterItems(searchText: string): Promise<ResponseSuccess<FilterResponse> | ResponseError | any> {
    const data = {
        searchText: searchText
    }
    return await client.post('/api/v1/guest/products/search?page=0&size=20', data);
}
