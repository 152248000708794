import {client} from "../api";
import {Auth, ResponseError, ResponseSuccess, UserID, VerifyCode} from "../models";


export async function login(email: string, password: string): Promise<ResponseSuccess<Auth> | ResponseError | any> {
    const body = {
        email: email,
        password: password
    }
    return await client.post<ResponseSuccess<Auth> | ResponseError>('api/v1/auth/sign-in', body)
}

export async function loginGoogleSrv(query: string): Promise<ResponseSuccess<Auth> | ResponseError | any> {
    return await client.get<ResponseSuccess<Auth> | ResponseError>(`/api/v1/oauth2/google/login${query}`)
}


export async function registration(email: string, password: string, code: string): Promise<ResponseSuccess<UserID> | ResponseError | any> {
    const body = {
        email: email,
        password: password,
        verificationCode: code
    }
    return await client.post<ResponseSuccess<Auth> | ResponseError>('/api/v1/auth/sign-up', body)
}


export async function getVerificationCode(email: string): Promise<ResponseSuccess<VerifyCode> | ResponseError | any> {
    const body = {
        email: email
    }
    return await client.post<ResponseSuccess<Auth> | ResponseError>('/api/v1/guest/email-verifications', body)
}