import React, {ReactElement} from 'react';
import HeaderComponent from '../components/Header/HeaderComponent';
import {Outlet} from 'react-router-dom';

// Define props for the layout component
// interface MainLayoutProps {
//     children: ReactElement
// }

// Create the layout component
const MainLayout: React.FC = () => {

    return (
        <section className='flex flex-col space-y-1'>

            {/* container for header */}
            <section className='h-auto'>
                <HeaderComponent/>
            </section>

            {/* container for main */}
            <section className='h-auto'>
                <Outlet/>
            </section>

            {/* container for footer */}
            <section className='h-48 border border-red-500'>

            </section>
        </section>
    );
};

export default MainLayout;