import React, {useState} from 'react';
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs';
import {GoDotFill, GoDot} from "react-icons/go";

interface Slide {
    url: string;
}

interface ImageSliderProps {
    images: string[]
}

const ImageView: React.FC<ImageSliderProps> = (props) => {
    const {images} = props;
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex: number) => {
        setCurrentIndex(slideIndex);
    };

    return (
        <div className='flex flex-row w-full space-x-1'>
            <div className='flex flex-row space-y-1 w-[20%] h-[500px] flex-wrap overflow-auto'>
                {images.map((image, imageIndex) => (
                    <img
                        key={imageIndex}
                        onClick={() => goToSlide(imageIndex)}
                        src={image}
                        className='cursor-pointer w-[80px] h-[80px] border border-navTextColor'
                    />
                ))}
            </div>
            <div className='relative group w-[80%]'>
                <div
                    style={{backgroundImage: `url(${images[currentIndex]})`, backgroundSize: "400px 500px"}}
                    className='w-[400px] h-[500px] bg-auto duration-500'
                ></div>
                {/* Left Arrow */}
                <div
                    className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactLeft onClick={prevSlide} size={30}/>
                </div>
                {/* Right Arrow */}
                <div
                    className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactRight onClick={nextSlide} size={30}/>
                </div>

            </div>
        </div>
    );
};

export default ImageView;