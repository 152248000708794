import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

// Define the Logo component
const Logo: React.FC = () => {
    const location = useLocation();
    useEffect(() => {
        console.log(process.env.PUBLIC_URL + location.pathname + "/images/logo.png")
    });

    return (
        <div className='flex justify-center items-center h-full lg:w-2/6 w-1/12'>
            <img alt='logo' className='w-56 hidden lg:block' src={process.env.PUBLIC_URL + "/images/logo.png"}/>
            <img alt='mini logo' className='w-14 lg:hidden' src={process.env.PUBLIC_URL + "/images/logo-mini.png"}/>
        </div>
    );
};

export default Logo;