import React, {useState, useRef} from 'react';
import {IoCloseOutline} from 'react-icons/io5';
import {RxHamburgerMenu} from 'react-icons/rx';
import useOutsideClick from '../../hooks/useOutsideClick';
import NavBody from './NavBody';

const menu = [
    {
        name: 'Kampanyalar',
        url: ''
    },
    {
        name: 'Yeni Gelenler',
        url: ''
    },
    {
        name: 'En çok satanlar',
        url: ''
    },
    {
        name: 'Kaçırılmayacak Fiyatlar',
        url: ''
    },
    {
        name: 'Setler',
        url: ''
    }
]


// Define the Nav Bar component
const Nav: React.FC = () => {
    const [click, setClick] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLLIElement>(null);

    const onClick = () => {
        setClick(true);
    }

    const handleClose = () => {
        setClick(false);
    };

    useOutsideClick(wrapperRef, handleClose);

    return (
        <div className='lg:block hidden bg-navColor h-12 relative'>
            <ul className='xl:container xl:mx-auto xl:w-[1300px] h-full flex flex-row items-center space-x-4'>
                <li
                    ref={wrapperRef}
                    className='relative h-full flex flex-row justify-center items-center space-x-2 cursor-pointer border-r border-navTextColor text-navTextColor px-2'
                    onClick={onClick}
                >
                    <p className='text-base font-bold text-center leading-none'>Menu</p>
                    {
                        click
                            ?
                            <div className='h-[30px] w-[30px]'><IoCloseOutline className='h-[30px] w-[25px]'/></div>
                            :
                            <RxHamburgerMenu className='h-[30px] w-[30px]'/>

                    }
                    {
                        click
                            ?
                            <NavBody handleClose={handleClose}/>
                            :
                            <></>
                    }
                </li>
                {
                    menu.map((item, index) => (
                        <li key={index} className='flex flex-row justify-center items-center space-x-2 cursor-pointer'>
                            <p className='text-navTextColor text-base font-bold w-28 text-center leading-none'>{item.name}</p>
                        </li>
                    ))
                }

            </ul>

        </div>
    );
};

export default Nav;