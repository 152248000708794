import React, {useRef, useState} from 'react';
import {IoSearchOutline} from "react-icons/io5";
import useOutsideClick from '../../hooks/useOutsideClick';
import {Link} from 'react-router-dom';
import {useFilterStore} from '../../state';


// Create the Search component
const Search: React.FC = () => {
    const [click, setClick] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState<string>("");
    const [getFilterItems] = useFilterStore(state => [
        state.getFilterItems
    ])

    const onClickSearch = () => {
        getFilterItems(value);
    }

    const onClick = () => {
        setClick(true);
    }

    const handleClose = () => {
        setClick(false);
    };

    useOutsideClick(wrapperRef, handleClose);

    return (
        <div className='h-full w-5/6 flex justify-center items-center'>
            <div
                ref={wrapperRef}
                className={`w-full h-14 flex flex-row border-2 rounded-md ${click ? 'border-mainColor bg-mainColor' : 'border-borderColor bg-borderColor '}`}
                onClick={onClick}
            >
                <div className='h-full w-[8%] flex justify-center items-center bg-white rounded-l-md'>
                    <IoSearchOutline
                        className={`h-[30px] w-[30px]  ${click ? 'text-mainColor ' : 'text-borderColor'}`}/>
                </div>
                <input
                    className='h-full w-[84%] focus:outline-none text-lg'
                    type="text"
                    name=""
                    id=""
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder='Ürün, kategori veya marka ara'
                />
                <Link to={`search?q=${value}`} onClick={onClickSearch}
                      className={`h-full w-[8%] ${click ? 'bg-mainColor ' : 'bg-borderColor'} rounded-r-md flex justify-center items-center`}>
                    <p className='text-white font-bold'>ARA</p>
                </Link>
            </div>
        </div>
    );

}

export default Search;