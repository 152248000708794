import '../../styles/pages/login.style.scss';

import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PasswordInput, TextInput} from "../../components/UI/Inputs";
import {MainButton} from "../../components/UI/Buttons";
import {useUserStore} from '../../state';


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    let location = useLocation();
    const [
        login,
        isLoading,
        errorEmail,
        errorPassword
    ] = useUserStore((state) => [
        state.login,
        state.isLoading,
        state.errorEmail,
        state.errorPassword
    ])

    useEffect(() => {

    }, [location]);

    const loginEvent = () => {
        if (email && password) {
            login(email, password, navigate);

        }
    }

    return (
        <div className="login-step">
            <TextInput
                placeholder='E-posta adresi veya telefon numarası'
                error={errorEmail}
                value={email}
                setValue={setEmail}
            />
            <PasswordInput
                placeholder="Password"
                error={errorPassword}
                value={password}
                setValue={setPassword}
            />
            <MainButton
                label="Giriş yap"
                submit={loginEvent}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Login;