import React from 'react';
import {Link} from 'react-router-dom';

interface ProductCardData {
    id: number;
    image: string;
    title: string;
    price: string;
    discount: boolean;
    procent: string;
    author: string;
}


// Create the ProductCard
const ProductCard: React.FC<ProductCardData> = (props) => {

    return (
        <div
            className="xl:w-[240px] xl:h-[390px] md:w-[220px] md:h-[370px] max-w-sm bg-white border border-gray-200 rounded-lg shadow flex flex-col space-y-2 cursor-grab p-1 px-3">
            <div className='flex justify-center items-center py-1 cursor-pointer'>
                <img className='w-48 h-64 object-contain' src={props.image} alt=""/>
            </div>
            <div className='flex flex-row items-center space-x-2'>
                <p className='leading-3 text-lg font-bold'>{props.price}</p>
                {
                    props.discount
                        ?
                        <div className='flex flex-row items-center space-x-2'>
                            <p className='leading-3 text-sm text-borderColor line-through'>579 ₽</p>
                            <div
                                className='h-[20px] max-w-[40px] rounded-sm bg-red-600 flex justify-center items-center px-1'>
                                <p className='text-xs font-bold text-white'>
                                    -{props.procent}
                                </p>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            <a className='cursor-pointer hover:text-hoverColor '>
                <Link to={"/details/" + props.id}>
                    <p className='h-8 leading-4 text-sm overflow-hidden text-ellipsis'>
                        {props.title}
                    </p>
                </Link>
            </a>
            <p className='leading-3 text-sm text-borderColor'>
                {props.author}
            </p>
        </div>
    );

}

export default ProductCard;