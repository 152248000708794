import React, {useEffect} from 'react';
import {useFilterStore} from '../../state';

import '../../styles/components/filter.style.scss';
import CheckboxList from './CheckBoxList';
import DropDown from '../UI/DropDown/DropDown';

const FilterList = () => {
    const [filterParameters] = useFilterStore(state => [
        state.searchableParameter
    ])

    useEffect(() => {

    }, []);

    return (
        <ul className='filter-list'>
            <li className='filter-list-item'>
                <ul>
                    {
                        filterParameters.categoryList
                            ?
                            filterParameters.categoryList.length > 0
                                ?
                                <li><DropDown label='Categories'
                                              option={<CheckboxList options={filterParameters.categoryList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.bookTypeList
                            ?
                            filterParameters.bookTypeList.length > 0
                                ?
                                <li><DropDown label='Book Types'
                                              option={<CheckboxList options={filterParameters.bookTypeList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.bookSegmentList
                            ?
                            filterParameters.bookSegmentList.length > 0
                                ?
                                <li><DropDown label='Segments'
                                              option={<CheckboxList options={filterParameters.bookSegmentList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.bookPaperQualityList
                            ?
                            filterParameters.bookPaperQualityList.length > 0
                                ?
                                <li><DropDown label='Paper Qualities'
                                              option={<CheckboxList options={filterParameters.bookPaperQualityList}/>}/>
                                </li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.bookCoverTypeList
                            ?
                            filterParameters.bookCoverTypeList.length > 0
                                ?
                                <li><DropDown label='Cover Types'
                                              option={<CheckboxList options={filterParameters.bookCoverTypeList}/>}/>
                                </li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.bookContentLanguageList
                            ?
                            filterParameters.bookContentLanguageList.length > 0
                                ?
                                <li><DropDown label='Content Languages'
                                              option={<CheckboxList
                                                  options={filterParameters.bookContentLanguageList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.bookPublishingBrandList
                            ?
                            filterParameters.bookPublishingBrandList.length > 0
                                ?
                                <li><DropDown label='Publishing Brands'
                                              option={<CheckboxList
                                                  options={filterParameters.bookPublishingBrandList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.seriesList
                            ?
                            filterParameters.seriesList.length > 0
                                ?
                                <li><DropDown label='Series'
                                              option={<CheckboxList options={filterParameters.seriesList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.publisherHouseList
                            ?
                            filterParameters.publisherHouseList.length > 0
                                ?
                                <li><DropDown label='Publisher Houses'
                                              option={<CheckboxList options={filterParameters.publisherHouseList}/>}/>
                                </li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.authorList
                            ?
                            filterParameters.authorList.length > 0
                                ?
                                <li><DropDown label='Authors'
                                              option={<CheckboxList options={filterParameters.authorList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                    {
                        filterParameters.brandList
                            ?
                            filterParameters.brandList.length > 0
                                ?
                                <li><DropDown label='Brands'
                                              option={<CheckboxList options={filterParameters.brandList}/>}/></li>
                                :
                                <></>
                            :
                            <></>
                    }
                </ul>
            </li>

        </ul>
    );
};

export default FilterList;